import { Controller } from "@hotwired/stimulus";

const lockable =
  (option) => option.closest("select").disabled ||
    (option.disabled && option.selected);

function hideButtonOf(listItem) {
  const removeButton = listItem.querySelector('button[class*="remove"]');
  if (removeButton instanceof HTMLButtonElement === false) return;

  removeButton.setAttribute("hidden", "hidden");

  const textSpan = [...listItem.children].find((child) => child instanceof HTMLSpanElement);
  if (!textSpan) return;

  listItem.style.paddingLeft = "0px";
  textSpan.style.paddingRight = "0.3125rem";
  textSpan.style.paddingLeft = "0.3125rem";
}

export default class extends Controller {
  connect() {
    $(this.element)
      .select2({
        templateSelection: ({ element: option, text }, { 0: listItem }) => {
          if (lockable(option)) hideButtonOf(listItem);
          return text;
        }
      });

    $(this.element)
      .on('select2:unselecting', (event) => {
        const option = event.params.args.data.element;
        if (lockable(option)) event.preventDefault();
      });
  }
}
